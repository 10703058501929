import { DevToolsState } from '@wix/comments-ooi-client/dist/types/component/components/dev-pannel/dev-tools-types';

export type DevSettingsConfig = {
  forceDoubleCommentBox?: 'true' | 'false';
};

export const resolveDevSettingsConfig = (
  devSettingsConfig: DevSettingsConfig,
  devToolsState: DevToolsState,
) => {
  if (Object.keys(devSettingsConfig).length === 0) {
    return devToolsState;
  }

  return {
    ...devToolsState,
    forceShowCommentBoxes: devSettingsConfig?.forceDoubleCommentBox === 'true',
  } as DevToolsState;
};
