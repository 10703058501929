import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import { CommentSortOptions } from '~common/common-types';
import { AdditionalReactions } from './Settings/settings-tab/reactions-section/additional-reactions.const';

export type ISettingsParams = {
  settingsUpdateTimestamp: SettingsParamType.String;
  isDisplayMobileOn: SettingsParamType.Boolean;
  isCommentsCountEnabled: SettingsParamType.Boolean;
  isDividerEnabled: SettingsParamType.Boolean;
  isSortingEnabled: SettingsParamType.Boolean;
  sortCommentsBy: CommentSortOptions;
  isAvatarsEnabled: SettingsParamType.Boolean;
  isPublishDateEnabled: SettingsParamType.Boolean;
  isMembersBadgeEnabled: SettingsParamType.Boolean;
  isReplyingToMarkingEnabled: SettingsParamType.Boolean;
  isImagePluginEnabled: SettingsParamType.Boolean;
  isVideoPluginEnabled: SettingsParamType.Boolean;
  isGiphyPluginEnabled: SettingsParamType.Boolean;
  isEmojiPluginEnabled: SettingsParamType.Boolean;
  isFileUploadPluginEnabled: SettingsParamType.Boolean;
  isPollPluginEnabled: SettingsParamType.Boolean;
  isDividerPluginEnabled: SettingsParamType.Boolean;
  isHtmlCodePluginEnabled: SettingsParamType.Boolean;
  isGalleryPluginEnabled: SettingsParamType.Boolean;
  isCodeBlockPluginEnabled: SettingsParamType.Boolean;
  isCollapsibleListPluginEnabled: SettingsParamType.Boolean;
  isTablePluginEnabled: SettingsParamType.Boolean;
  isStoresPluginEnabled: SettingsParamType.Boolean;
  isEventsPluginEnabled: SettingsParamType.Boolean;
  isBookingsPluginEnabled: SettingsParamType.Boolean;
  isYouTubePluginEnabled: SettingsParamType.Boolean;
  isSoundCloudPluginEnabled: SettingsParamType.Boolean;
  isSpotifyPluginEnabled: SettingsParamType.Boolean;
  isInstagramPluginEnabled: SettingsParamType.Boolean;
  isTwitterPluginEnabled: SettingsParamType.Boolean;
  isTikTokPluginEnabled: SettingsParamType.Boolean;
  isTextEditingEnabled: SettingsParamType.Boolean;
  isAddingLinksEnabled: SettingsParamType.Boolean;
  commentsOnFirstPage: SettingsParamType.Number;
  commentsOnLoadMore: SettingsParamType.Number;
  repliesOnFirstPage: SettingsParamType.Number;
  repliesOnLoadMore: SettingsParamType.Number;
  isCommentsCountEnabledMobile: SettingsParamType.Boolean;
  isDividerEnabledMobile: SettingsParamType.Boolean;
  isSortingEnabledMobile: SettingsParamType.Boolean;
  isAvatarsEnabledMobile: SettingsParamType.Boolean;
  isPublishDateEnabledMobile: SettingsParamType.Boolean;
  isMembersBadgeEnabledMobile: SettingsParamType.Boolean;
  isReplyingToMarkingEnabledMobile: SettingsParamType.Boolean;
  isCommentingLocked: SettingsParamType.Boolean;
  headerTitle: SettingsParamType.String;
  placeholderText: SettingsParamType.String;
  publishButtonText: SettingsParamType.String;
  likeButtonText: SettingsParamType.String;
  replyButtonText: SettingsParamType.String;
  ratingSelectorText: SettingsParamType.String;
  isHeaderLoginEnabled: SettingsParamType.Boolean;
  additionalReactions: { reactions: AdditionalReactions[] };
  isAdminBadgeEnabled: SettingsParamType.Boolean;
  adminBadgeText: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  settingsUpdateTimestamp: {
    type: SettingsParamType.String,
    getDefaultValue: () => new Date().toISOString(),
  },
  isDisplayMobileOn: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  isCommentsCountEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isCommentsCountEnabledMobile: {
    getDefaultValue: () => true,
  },
  isDividerEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isDividerEnabledMobile: {
    getDefaultValue: () => true,
  },
  isSortingEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isSortingEnabledMobile: {
    getDefaultValue: () => true,
  },
  sortCommentsBy: {
    getDefaultValue: () => CommentSortOptions.newest,
    inheritDesktop: true,
  },
  isAvatarsEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isPublishDateEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isMembersBadgeEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isReplyingToMarkingEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isAvatarsEnabledMobile: {
    getDefaultValue: () => true,
  },
  isPublishDateEnabledMobile: {
    getDefaultValue: () => true,
  },
  isMembersBadgeEnabledMobile: {
    getDefaultValue: () => true,
  },
  isReplyingToMarkingEnabledMobile: {
    getDefaultValue: () => true,
  },
  isImagePluginEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isVideoPluginEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isGiphyPluginEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isEmojiPluginEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isFileUploadPluginEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isPollPluginEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isDividerPluginEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isHtmlCodePluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isGalleryPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isCodeBlockPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isCollapsibleListPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isTablePluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isStoresPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isEventsPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isBookingsPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isYouTubePluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isSoundCloudPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isSpotifyPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isInstagramPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isTwitterPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isTikTokPluginEnabled: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  isTextEditingEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  isAddingLinksEnabled: {
    getDefaultValue: () => true,
    inheritDesktop: true,
  },
  commentsOnFirstPage: {
    getDefaultValue: () => 10,
    inheritDesktop: true,
  },
  commentsOnLoadMore: {
    getDefaultValue: () => 10,
    inheritDesktop: true,
  },
  repliesOnFirstPage: {
    getDefaultValue: () => 2,
    inheritDesktop: true,
  },
  repliesOnLoadMore: {
    getDefaultValue: () => 5,
    inheritDesktop: true,
  },
  isCommentingLocked: {
    getDefaultValue: () => false,
    inheritDesktop: true,
  },
  headerTitle: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('settings.textTab.headerTitleTextDefault'),
  },
  placeholderText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('settings.textTab.placeholderTextDefault'),
  },
  publishButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('settings.textTab.publishButtonTextDefault'),
  },
  likeButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('settings.textTab.likeButtonTextDefault'),
  },
  replyButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('settings.textTab.replyButtonTextDefault'),
  },
  ratingSelectorText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('settings.settingsTab.ratingSelectorTextDefault'),
  },
  isHeaderLoginEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  additionalReactions: {
    getDefaultValue: () => ({ reactions: [] }),
  },
  isAdminBadgeEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  adminBadgeText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('settings.textTab.adminBadgeTextDefault'),
  },
});
