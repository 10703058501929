import { Configuration, PluginConfigEditorType } from '@wix/comments-ooi-client';
import {
  IMAGE_TYPE,
  VIDEO_TYPE,
  LINK_TYPE,
  HTML_TYPE,
  FILE_UPLOAD_TYPE,
  POLL_TYPE,
  GALLERY_TYPE,
  DIVIDER_TYPE,
  COLLAPSIBLE_LIST_TYPE,
  GIPHY_TYPE,
  TABLE_TYPE,
  VERTICAL_EMBED_TYPE,
  AUDIO_TYPE,
  EMOJI_TYPE,
  CODE_BLOCK_TYPE,
  LINK_PREVIEW_TYPE,
} from 'wix-rich-content-common';
import { pluginVideo as pluginVideoViewer } from 'wix-rich-content-plugin-video/loadable/viewer';
import { pluginImage as pluginImageViewer } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginLink as pluginLinkViewer } from 'wix-rich-content-plugin-link/loadable/viewer';
import { pluginHtml as pluginHtmlViewer } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginFileUpload as pluginFileUploadViewer } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginPoll as pluginPollViewer } from 'wix-rich-content-plugin-social-polls/loadable/viewer';
import { pluginGallery as pluginGalleryViewer } from 'wix-rich-content-plugin-gallery/loadable/viewer';
import { pluginDivider as pluginDividerViewer } from 'wix-rich-content-plugin-divider/loadable/viewer';
import { pluginCollapsibleList as pluginCollapsibleListViewer } from 'wix-rich-content-plugin-collapsible-list/loadable/viewer';
import { pluginGiphy as pluginGiphyViewer } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginTable as pluginTableViewer } from 'wix-rich-content-plugin-table/loadable/viewer';
import { pluginVerticalEmbed as pluginVerticalEmbedViewer } from 'wix-rich-content-plugin-vertical-embed/loadable/viewer';
import { pluginAudio as pluginAudioViewer } from 'wix-rich-content-plugin-audio/loadable/viewer';
import { pluginEmoji as pluginEmojiViewer } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginCodeBlock as pluginCodeBlockViewer } from 'wix-rich-content-plugin-code-block/viewer';
import { WidgetSettings } from './get-widget-settings';

const GIPHY_SDK_KEY = 'T9NBE0QicvR61jiGYC6nDPohJdzpcYed';

export const getPluginConfig = (settings: WidgetSettings): Configuration['pluginConfig'] => {
  return {
    type: 'async',
    loadEditorPluginsAsync: createEditorPluginLoader(settings),
    plugins: {
      [IMAGE_TYPE]: { viewerModule: pluginImageViewer },
      [VIDEO_TYPE]: { viewerModule: pluginVideoViewer },
      [LINK_TYPE]: { viewerModule: pluginLinkViewer },
      [HTML_TYPE]: { viewerModule: pluginHtmlViewer },
      [FILE_UPLOAD_TYPE]: { viewerModule: pluginFileUploadViewer },
      [POLL_TYPE]: { viewerModule: pluginPollViewer },
      [GALLERY_TYPE]: { viewerModule: pluginGalleryViewer },
      [DIVIDER_TYPE]: { viewerModule: pluginDividerViewer },
      [COLLAPSIBLE_LIST_TYPE]: { viewerModule: pluginCollapsibleListViewer },
      [GIPHY_TYPE]: {
        viewerModule: pluginGiphyViewer,
        config: { giphySdkApiKey: GIPHY_SDK_KEY },
      },
      [TABLE_TYPE]: { viewerModule: pluginTableViewer },
      [VERTICAL_EMBED_TYPE]: { viewerModule: pluginVerticalEmbedViewer },
      [AUDIO_TYPE]: { viewerModule: pluginAudioViewer },
      [EMOJI_TYPE]: { viewerModule: pluginEmojiViewer },
      [CODE_BLOCK_TYPE]: { viewerModule: pluginCodeBlockViewer },
    },
  };
};

const createEditorPluginLoader = (settings: WidgetSettings) => {
  return async (): Promise<PluginConfigEditorType> => {
    const pluginConfig: Record<string, any> = {};

    if (settings.isImagePluginEnabled) {
      const { pluginImage } = await import(
        /* webpackChunkName: "comments-widget-plugin-image" */
        'wix-rich-content-plugin-image'
      );
      pluginConfig[IMAGE_TYPE] = {
        editorModule: pluginImage,
      };
    }
    if (settings.isAddingLinksEnabled) {
      const { pluginLink } = await import(
        /* webpackChunkName: "comments-widget-plugin-link" */
        'wix-rich-content-plugin-link'
      );
      pluginConfig[LINK_TYPE] = {
        editorModule: pluginLink,
      };
    }
    if (settings.isVideoPluginEnabled || settings.isYouTubePluginEnabled) {
      const { pluginVideo, videoButtonsTypes } = await import(
        /* webpackChunkName: "comments-widget-plugin-video" */
        'wix-rich-content-plugin-video'
      );
      pluginConfig[VIDEO_TYPE] = {
        editorModule: pluginVideo,
        config: {
          exposeButtons: [
            settings.isVideoPluginEnabled ? videoButtonsTypes.video : undefined,
            settings.isYouTubePluginEnabled ? videoButtonsTypes.youTube : undefined,
          ],
        },
      };
    }
    if (settings.isGiphyPluginEnabled) {
      const { pluginGiphy } = await import(
        /* webpackChunkName: "comments-widget-plugin-giphy" */
        'wix-rich-content-plugin-giphy'
      );
      pluginConfig[GIPHY_TYPE] = {
        editorModule: pluginGiphy,
        config: { giphySdkApiKey: GIPHY_SDK_KEY },
      };
    }
    if (settings.isDividerPluginEnabled) {
      const { pluginDivider } = await import(
        /* webpackChunkName: "comments-widget-plugin-divider" */
        'wix-rich-content-plugin-divider'
      );
      pluginConfig[DIVIDER_TYPE] = {
        editorModule: pluginDivider,
      };
    }
    if (settings.isGalleryPluginEnabled) {
      const { pluginGallery } = await import(
        /* webpackChunkName: "comments-widget-plugin-gallery" */
        'wix-rich-content-plugin-gallery'
      );
      pluginConfig[GALLERY_TYPE] = {
        editorModule: pluginGallery,
      };
    }
    if (settings.isSpotifyPluginEnabled || settings.isSoundCloudPluginEnabled) {
      const { pluginAudio, audioButtonsTypes } = await import(
        /* webpackChunkName: "comments-widget-plugin-audio" */
        'wix-rich-content-plugin-audio'
      );
      pluginConfig[AUDIO_TYPE] = {
        editorModule: pluginAudio,
        config: {
          exposeButtons: [
            settings.isSpotifyPluginEnabled ? audioButtonsTypes.spotify : undefined,
            settings.isSoundCloudPluginEnabled ? audioButtonsTypes.soundCloud : undefined,
          ],
        },
      };
    }
    if (settings.isTablePluginEnabled) {
      const { pluginTable } = await import(
        /* webpackChunkName: "comments-widget-plugin-table" */
        'wix-rich-content-plugin-table'
      );
      pluginConfig[TABLE_TYPE] = {
        editorModule: pluginTable,
      };
    }
    if (settings.isPollPluginEnabled) {
      const { pluginPoll } = await import(
        /* webpackChunkName: "comments-widget-plugin-social-polls" */
        'wix-rich-content-plugin-social-polls'
      );
      pluginConfig[POLL_TYPE] = {
        editorModule: pluginPoll,
      };
    }
    if (settings.isHtmlCodePluginEnabled) {
      const { pluginHtml } = await import(
        /* webpackChunkName: "comments-widget-plugin-html" */
        'wix-rich-content-plugin-html'
      );
      pluginConfig[HTML_TYPE] = {
        editorModule: pluginHtml,
      };
    }
    if (settings.isCollapsibleListPluginEnabled) {
      const { pluginCollapsibleList } = await import(
        /* webpackChunkName: "comments-widget-plugin-collapsible-list" */
        'wix-rich-content-plugin-collapsible-list'
      );
      pluginConfig[COLLAPSIBLE_LIST_TYPE] = {
        editorModule: pluginCollapsibleList,
      };
    }
    if (settings.isFileUploadPluginEnabled) {
      const { pluginFileUpload } = await import(
        /* webpackChunkName: "comments-widget-plugin-file-upload" */
        'wix-rich-content-plugin-file-upload'
      );
      pluginConfig[FILE_UPLOAD_TYPE] = {
        editorModule: pluginFileUpload,
      };
    }
    if (
      settings.isStoresPluginEnabled ||
      settings.isEventsPluginEnabled ||
      settings.isBookingsPluginEnabled
    ) {
      const { pluginVerticalEmbed, verticalEmbedProviders } = await import(
        /* webpackChunkName: "comments-widget-plugin-vertical-embed" */
        'wix-rich-content-plugin-vertical-embed'
      );
      pluginConfig[VERTICAL_EMBED_TYPE] = {
        editorModule: pluginVerticalEmbed,
        config: {
          exposeEmbedButtons: [
            settings.isStoresPluginEnabled && verticalEmbedProviders.product,
            settings.isEventsPluginEnabled && verticalEmbedProviders.event,
            settings.isBookingsPluginEnabled && verticalEmbedProviders.booking,
          ],
        },
      };
    }
    if (settings.isEmojiPluginEnabled) {
      const { pluginEmoji } = await import(
        /* webpackChunkName: "comments-widget-plugin-emoji" */
        'wix-rich-content-plugin-emoji'
      );
      pluginConfig[EMOJI_TYPE] = {
        editorModule: pluginEmoji,
      };
    }
    if (settings.isEmojiPluginEnabled) {
      const { pluginEmoji } = await import(
        /* webpackChunkName: "comments-widget-plugin-emoji" */
        'wix-rich-content-plugin-emoji'
      );
      pluginConfig[EMOJI_TYPE] = {
        editorModule: pluginEmoji,
      };
    }
    if (settings.isCodeBlockPluginEnabled) {
      const { pluginCodeBlock } = await import(
        /* webpackChunkName: "comments-widget-plugin-code-block" */
        'wix-rich-content-plugin-code-block'
      );
      pluginConfig[CODE_BLOCK_TYPE] = {
        editorModule: pluginCodeBlock,
      };
    }
    if (
      settings.isInstagramPluginEnabled ||
      settings.isTwitterPluginEnabled ||
      settings.isTikTokPluginEnabled
    ) {
      const { pluginLinkPreview, LinkPreviewProviders } = await import(
        /* webpackChunkName: "comments-widget-plugin-link-preview" */
        'wix-rich-content-plugin-link-preview'
      );
      pluginConfig[LINK_PREVIEW_TYPE] = {
        editorModule: pluginLinkPreview,
        config: {
          enableEmbed: true,
          exposeEmbedButtons: [
            settings.isInstagramPluginEnabled && LinkPreviewProviders.Instagram,
            settings.isTwitterPluginEnabled && LinkPreviewProviders.Twitter,
            settings.isTikTokPluginEnabled && LinkPreviewProviders.TikTok,
          ],
        },
      };
    }

    return pluginConfig;
  };
};
